<template>
	<div>
		<li class="list-group-item py-3 mt-md-4">
			<h5 class="m-0" v-if="book_data.type == 'list_order'">Order - {{ book_data.name }}</h5>
			<h5 class="m-0" v-else>Preorder</h5>
		</li>

		<li class="list-group-item py-4">
			<div class="row book">
				<div class="col-3 col-lg-2">
					<div class="book-ratio" :style="{'backgroundImage':'url('+book_data.photo+')'}"></div>
				</div>
				<div class="col pl-0">
					<div class="row d-flex h-100">
						<div class="col-12 align-self-start">
							<div class="row">
								<div class="col-12">
									<p class="book-title opt2" style="-webkit-box-orient: vertical" v-html="book_data.title"></p>
								</div>
								<div class="col-12">
									<p class="d-inline-block m-0">by </p>
									<a href="#!" class="text-info">{{ book_data.author }}</a>
								</div>
							</div>
						</div>
						<div class="col-12 align-self-end">
							<div class="row">
								<div class="col-6">
									<p class="m-0">{{ book_data.quantity }} pcs</p>
									<p class="m-0" v-if="book_data.is_dollar">${{ book_data.disc_price_in_dollar }}</p>
									<h6 class="text-primary m-0">Rp. {{ book_data.price }}</h6>
								</div>
								<div class="col-6 text-right align-self-end">
									<p class="m-0">Total {{ book_data.type == 'list_order' ? 'DP' : '' }}</p>
									<h6 class="text-primary m-0">Rp. {{ book_data.total_price }}</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</li>

		<li class="list-group-item py-3">
			<h5 class="m-0">Metode Pengiriman</h5>
		</li>
		<li class="list-group-item py-3">
			<ul class="list-group border-none">
				<li class="list-group-item border-left-0 border-top-0 border-right-0 px-0 pt-0 pb-3">
					<h6 class="m-0">Pilih Durasi</h6>
				</li>
				<li class="list-group-item border-left-0 border-right-0 px-0 py-3">
					<div class="custom-control custom-radio">
						<input type="radio" :id="'radio_reg_'+index_data" :name="'customRadio'+index_data" class="custom-control-input" v-on:click="duration(book_data.id, 'regular', index_data)" :checked="true">
						<label class="custom-control-label" :for="'radio_reg_'+index_data"><b>Regular</b><br>(2-4 hari)</label>
					</div>
					<div class="custom-control custom-radio mt-2">
						<input type="radio" :id="'radio_next_day_'+index_data" :name="'customRadio'+index_data" class="custom-control-input" v-on:click="duration(book_data.id, 'next_day', index_data)">
						<label class="custom-control-label" :for="'radio_next_day_'+index_data"><b>Next Day</b><br>(1 hari)</label>
					</div>
				</li>

				<li class="list-group-item border-left-0 border-right-0 px-0 py-3">
					<h6 class="m-0">Kurir Pilihan</h6>
				</li>
				<li class="list-group-item border-left-0 border-bottom-0 border-right-0 px-0 pb-0 pt-3">
					<div class="row">
						<div class="col-12">
							<p class="m-0 d-inline-block" v-html="book_data.courier_info"></p>
							<a href="#!" class="text-primary ml-2" data-toggle="modal" :data-target="'#KurirPilihanModalCenter'+index_data" v-on:click="open_modal(index_data, book_data.name)">Ubah</a>
						</div>
					</div>
				</li>
			</ul>
		</li>
		<div class="modal fade" :id="'KurirPilihanModalCenter'+index_data" tabindex="-1" role="dialog" aria-labelledby="KurirPilihanModalCenterTitle" aria-hidden="true">
			<input type="hidden" v-model="book_data.id">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="KurirPilihanModalCenterTitle">Tujuan Pengiriman - {{ book_data.name }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body py-0">
						<ul class="list-group list-group-flush" v-if="show">
							<li class="list-group-item p-3 border-left-0" v-for="(data_courier, index_data) in courier_list">
								<div class="custom-control custom-radio">
									<input type="radio" :id="'kurirPilihan'+index_data+'_'+book_data.id" :name="'kurirPilihan_'+book_data.id" class="custom-control-input" :value="index_data" v-model="courier">
									<label class="custom-control-label w-100 pl-2" :for="'kurirPilihan'+index_data+'_'+book_data.id">
										<p class="m-0"><b>{{ data_courier.name_service }}</b></p>
										<p class="m-0">Rp. {{ data_courier.cost }}</p>
									</label>
								</div>
							</li>
						</ul>
						<div class="col-lg-6 offset-lg-3 col-8 offset-2 mt-4 text-center" v-else>
							<img :src="asset_alert" class="img-fluid" alt="Courier Not Found">
							<h5 class="text-center w-100 mb-4 mt-4">Courier Not Found</h5>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
						<button type="button" class="btn btn-primary" v-on:click="save_courier(index_data)">Simpan</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:[
		'book_data', 'index_data', 'show', 'asset_alert'
		],
		mounted() {
		},
		methods:{
			
		}
	}
</script>
