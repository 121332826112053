<template>
	<ul class="list-group">
		<li class="list-group-item py-3">
			<h5 class="m-0">Ringkasan Belanja</h5>
		</li>
		<li class="list-group-item py-4">
			<div class="row">
				<div class="col-6">
					<p class="m-0">Subtotal</p>
				</div>
				<div class="col-6 text-right">
					<p class="m-0"><b>{{ sub_total }}</b></p>
				</div>
				<div class="col-6 mt-3">
					<p class="m-0">Biaya Kirim</p>
				</div>
				<div class="col-6 mt-3 text-right">
					<p class="m-0"><b>{{ ongkir }}</b></p>
				</div>
				<div class="col-6 mt-3">
					<p class="m-0">Biaya Asuransi</p>
				</div>
				<div class="col-6 mt-3 text-right">
					<p class="m-0"><b>Rp. 10.000</b></p>
				</div>
				<div class="col-6 mt-3">
					<p class="m-0">Potongan Kupon</p>
				</div>
				<div class="col-6 mt-3 text-right">
					<p class="m-0"><b>Rp. 0</b></p>
				</div>
				<div class="col-6 mt-3">
					<p class="m-0">Total Belanja</p>
				</div>
				<div class="col-6 mt-3 text-right">
					<h4 class="text-primary">Rp. 109.500</h4>
				</div>
			</div>
		</li>
		<li class="list-group-item py-4">
			<p class="mb-2">Kode Promo</p>
			<div class="input-group mb-3 mb-md-0">
				<input id="exampleInputEmail1" type="text" class="form-control" placeholder="Contoh: MERDEKA17" aria-label="Contoh: MERDEKA17" aria-describedby="button-addon2">
				<div class="input-group-append">
					<button class="btn btn-primary" type="button" id="button-addon2">Use</button>
				</div>
			</div>
		</li>
		<li class="list-group-item py-3 d-none d-md-block">
			<a name="" id="" class="btn btn-primary btn-block d-none d-md-block" :href="url_payment" role="button">Lanjut Pembayaran</a>
		</li>
	</ul>
</template>

<script>
	export default {
		props:[
		'sub_total', 'ongkir', 'url_payment',
		],
		mounted() {
			console.log('Component mounted.')
		}
	}
</script>
