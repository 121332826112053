var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "list-group" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("li", { staticClass: "list-group-item py-4" }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 text-right" }, [
          _c("p", { staticClass: "m-0" }, [
            _c("b", [_vm._v(_vm._s(_vm.sub_total))])
          ])
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 mt-3 text-right" }, [
          _c("p", { staticClass: "m-0" }, [
            _c("b", [_vm._v(_vm._s(_vm.ongkir))])
          ])
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8)
      ])
    ]),
    _vm._v(" "),
    _vm._m(9),
    _vm._v(" "),
    _c("li", { staticClass: "list-group-item py-3 d-none d-md-block" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary btn-block d-none d-md-block",
          attrs: { name: "", id: "", href: _vm.url_payment, role: "button" }
        },
        [_vm._v("Lanjut Pembayaran")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "list-group-item py-3" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Ringkasan Belanja")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("Subtotal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("Biaya Kirim")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("Biaya Asuransi")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3 text-right" }, [
      _c("p", { staticClass: "m-0" }, [_c("b", [_vm._v("Rp. 10.000")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("Potongan Kupon")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3 text-right" }, [
      _c("p", { staticClass: "m-0" }, [_c("b", [_vm._v("Rp. 0")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3" }, [
      _c("p", { staticClass: "m-0" }, [_vm._v("Total Belanja")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 mt-3 text-right" }, [
      _c("h4", { staticClass: "text-primary" }, [_vm._v("Rp. 109.500")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "list-group-item py-4" }, [
      _c("p", { staticClass: "mb-2" }, [_vm._v("Kode Promo")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group mb-3 mb-md-0" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: {
            id: "exampleInputEmail1",
            type: "text",
            placeholder: "Contoh: MERDEKA17",
            "aria-label": "Contoh: MERDEKA17",
            "aria-describedby": "button-addon2"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button", id: "button-addon2" }
            },
            [_vm._v("Use")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }