var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("ul", { staticClass: "list-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("li", { staticClass: "list-group-item" }, [
        _c("p", { staticClass: "m-0" }, [_vm._v("Total Belanja")]),
        _vm._v(" "),
        _c("h4", { staticClass: "text-primary" }, [
          _vm._v("Rp. " + _vm._s(_vm.total))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-block mt-4",
            attrs: { name: "", id: "", href: _vm.url, role: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("continue_checkout", _vm.total)
              }
            }
          },
          [_vm._v("Lanjut Checkout")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "list-group-item" }, [
      _c("h5", { staticClass: "m-0" }, [_vm._v("Ringkasan Belanja")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }