<template>
	<div class="dropdown">
		<button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			{{ title_sort_by }}
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
			<div v-for="(data, index) in arr_sort">
				<div class="dropdown-divider" v-if="(index)%2 == 0 && index>0"></div>
				<a class="dropdown-item" href="#" v-on:click="$emit('sort', data)">{{ data.label }}</a>
			</div>
			<!-- <a class="dropdown-item" href="#">Title A - Z</a>
			<a class="dropdown-item" href="#">Title Z - A</a>
			<div class="dropdown-divider"></div>
			<a class="dropdown-item" v-model="price_desc" href="#!" v-on:click="sort">Price High - Low</a>
			<a class="dropdown-item" v-model="price_asc" href="#!" v-on:click="sort">Price Low - High</a>
			<div class="dropdown-divider"></div>
			<a class="dropdown-item" href="#">Last Release</a> -->
		</div>
	</div>
</template>

<script>
	export default {
		props:[
		'title_sort_by', 'sort_by_title_asc', 'sort_by_title_desc', 'sort_by_price_desc', 'sort_by_price_asc', 'sort_by_release'
		],
		data(){
			return {
				arr_sort: [
				{
					data: 'name_asc',
					label: this.sort_by_title_asc
				},
				{
					data: 'name_desc',
					label: this.sort_by_title_desc
				},
				{
					data: 'price_desc',
					label: this.sort_by_price_desc
				},
				{
					data: 'price_asc',
					label: this.sort_by_price_asc
				},
				{
					data: 'last_release',
					label: this.sort_by_release
				},
				]
			}
		},
		mounted(){
			console.log('Component mounted.')
		}
	}
</script>
