<template>
	<div class="col-12">
		<ul class="list-group">
			<li class="list-group-item">
				<h5 class="m-0">Ringkasan Belanja</h5>
			</li>
			<li class="list-group-item">
				<p class="m-0">Total Belanja</p>
				<h4 class="text-primary">Rp. {{ total }}</h4>
				<a name="" id="" class="btn btn-primary btn-block mt-4" :href="url" role="button" v-on:click="$emit('continue_checkout', total)">Lanjut Checkout</a>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props:[
		'total', 'url',
		],
		mounted() {
			console.log('Component mounted.')
		}
	}
</script>
