var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "book" }, [
    _c("div", {
      staticClass: "book-ratio",
      style: { backgroundImage: "url(" + _vm.book_data.photo + ")" },
      on: {
        click: function($event) {
          return _vm.$emit("detail", _vm.book_data.id)
        }
      }
    }),
    _vm._v(" "),
    _c("a", {
      staticClass: "book-title",
      staticStyle: { "-webkit-box-orient": "vertical" },
      attrs: { href: "#!" },
      domProps: { innerHTML: _vm._s(_vm.book_data.title) },
      on: {
        click: function($event) {
          return _vm.$emit("detail", _vm.book_data.id)
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "book-author" }, [
      _c("p", [_vm._v("by ")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "text-wrap",
          attrs: { href: "#!" },
          on: {
            click: function($event) {
              return _vm.$emit("author_link", _vm.book_data.author)
            }
          }
        },
        [_vm._v(_vm._s(_vm.book_data.author))]
      )
    ]),
    _vm._v(" "),
    _vm.book_data.discount != 0
      ? _c("p", { staticClass: "price-discount" }, [
          _vm._v(_vm._s(_vm.book_data.price))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", { staticClass: "price" }, [
      _vm._v(_vm._s(_vm.book_data.disc_price))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }