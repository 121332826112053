/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');
// var requireTest = require.context('./', true, /.js$/);
// requireTest.keys().forEach(requireTest);

// require('../../node-modules/multi-clamp/MultiClamp');

// window.MultiClamp = require('multi-clamp');

require('../../node_modules/numeral');
window.Vue = require('vue');
window.DatePicker = require('vue2-datepicker');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('alert', require('./components/Alert.vue').default);
Vue.component('sort', require('./components/Sort.vue').default);
Vue.component('filter_book', require('./components/Filter.vue').default);
Vue.component('cart_overview', require('./components/CartOverview.vue').default);
Vue.component('checkout_overview', require('./components/CheckoutSidebar.vue').default);
Vue.component('select_vue', require('vue-select'));
// Vue.component('date-picker', require('./components/DatePicker.vue').default);
Vue.component('book', require('./components/Book.vue').default);
Vue.component('checkout_list', require('./components/CheckoutList.vue').default);
Vue.component('order_history', require('./components/HistoryOrder.vue').default);

// Vue.component('search', require('./components/Search.vue').default);

var Paginate = require('vuejs-paginate')
Vue.component('paginate', Paginate)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
