<template>
  <div class="alert fade show w-100" :class="{'alert-danger': type=='error', 'alert-success' : type == 'success', 'm-0 text-center': from == 'add_cart'}" role="alert" v-show="is_show">
    <p class="m-0" v-html="message"></p>
  </div>
</template>

<script>
  export default {
    props:[
      'is_show', 'message', 'type', 'from',
    ],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
