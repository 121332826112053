var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-outline-primary dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuButton",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.title_sort_by) + "\n\t")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        attrs: { "aria-labelledby": "dropdownMenuButton" }
      },
      _vm._l(_vm.arr_sort, function(data, index) {
        return _c("div", [
          index % 2 == 0 && index > 0
            ? _c("div", { staticClass: "dropdown-divider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.$emit("sort", data)
                }
              }
            },
            [_vm._v(_vm._s(data.label))]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }