<template>
	<ul class="list-group list-group-flush p-3 p-lg-4 mb-3 border">
		<li class="list-group-item pt-0 pb-3 pb-lg-4 border-top-0">
			<div class="row">
				<div class="col-6 p-0">
					<p class="m-0 font-weight-bold">{{ history_arr.date }}</p>
					<p class="m-0 font-weight-bold">#{{ history_arr.id }}</p>
				</div>
				<div class="col-6 p-0 text-right">
					<p class="m-0 font-weight-bold">{{ history_arr.courier_name }}</p>
					<p class="m-0 font-weight-bold">Rp. {{ history_arr.cost_delivery }} ({{ history_arr.weight }}Gr)</p>
				</div>
			</div>
		</li>

		<li class="list-group-item py-3 py-lg-4" v-for="data1 in history_arr.list_book">
			<div class="row book">
				<div class="col-12 col-lg-6">
					<div class="row">
						<div class="col-3 pl-0">
							<div class="book-ratio" :style="{'backgroundImage':'url('+data1.photo+')'}" v-on:click="book_detail(data1.id)"></div>
						</div>
						<div class="col pl-0">
							<div class="row d-flex h-100">
								<div class="col-12 align-self-start">
									<div class="row">
										<div class="col-12">
											<p class="book-title opt2" style="-webkit-box-orient: vertical; cursor: pointer;" v-on:click="book_detail(data1.id)">{{ data1.title }}</p>
										</div>
										<div class="col-12">
											<p class="d-inline-block m-0">by </p>
											<a href="#!" class="text-info">{{ data1.author }}</a>
										</div>
									</div>
								</div>
								<div class="col-12 align-self-end">
									<p class="text-primary d-inline-block m-0 font-weight-bold">Rp. {{ data1.price }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 pr-0 d-none d-lg-flex flex-column justify-content-end align-items-end">
					<div class="d-flex justify-content-start">
						<p class="m-0">Qty: </p>
						<p class="m-0 ml-2 text-primary font-weight-bold">{{ data1.quantity }}</p>
					</div>
					<div class="d-flex justify-content-start">
						<p class="m-0">Subtotal</p>
						<p class="m-0 ml-2 text-primary font-weight-bold">Rp. {{ data1.sub_total }}</p>
					</div>
					<div class="d-flex justify-content-start">
						<p class="m-0">{{ data1.type_disc }}</p>
						<p class="m-0 ml-2 text-primary font-weight-bold">{{ data1.discount_mount }}%</p>
					</div>
				</div>
				<div class="col-12 px-0 mt-3 d-flex justify-content-between d-lg-none">
					<div class="d-flex justify-content-start">
						<p class="m-0">Qty: </p>
						<p class="m-0 ml-2 text-primary font-weight-bold">{{ data1.quantity }}</p>
					</div>
					<div class="d-flex justify-content-start">
						<p class="m-0">Subtotal</p>
						<p class="m-0 ml-2 text-primary font-weight-bold">Rp. {{ data1.sub_total }}</p>
					</div>
					<div class="d-flex justify-content-start">
						<p class="m-0">{{ data1.type_disc }}</p>
						<p class="m-0 ml-2 text-primary font-weight-bold">{{ data1.discount_mount }}%</p>
					</div>
				</div>
			</div>
		</li>

		<li class="list-group-item p-0 pt-3 pt-lg-4 border-bottom-0" v-if="history_arr.status_order != 'canceled'">
			<div class="row">
				<div class="col-12 mb-3 text-right" v-if="history_arr.status_order == 'order_arrived'">
					<button class="btn btn-sm btn-primary" type="button" data-toggle="modal" :data-target="'#order_complete'+history_arr.id">Pesanan Selesai</button>
				</div>
				<div class="modal fade" :id="'order_complete'+history_arr.id" tabindex="-1" role="dialog" aria-labelledby="order_completeTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-scrollable" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="confirm_modalTitle">Selesaikan Pesanan</h5>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body py-0">
								<p class="mt-2">Yakin pesanan anda sudah selesai?</p>
							</div>
							<div class="modal-footer">
								<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
								<button type="button" class="btn btn-primary" v-on:click="$emit('complete_order', history_arr.id)">Yes</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-auto">
					<a :href="invoice_url+history_arr.id" class="text-primary">View Invoice</a>
				</div>
				<div class="col text-right">
					<p class="d-inline-block m-0">Total Belanja: </p>
					<p class="d-inline-block m-0 ml-2 text-primary font-weight-bold">Rp. {{ history_arr.total_belanja }}</p>
				</div>
			</div>
			<div class="d-flex justify-content-between align-items-center">
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props:[
		'history_arr', 'invoice_url', 'detail_url'
		],
		mounted() {
			// console.log(this.history_arr)
		},
		methods:{
			book_detail(id){
				location.href = this.detail_url+id
			}
		}
	}
</script>
