<template>
	<div class="book">
		<div class="book-ratio" :style="{'backgroundImage':'url('+book_data.photo+')'}" v-on:click="$emit('detail', book_data.id)"></div>
		<a :href="'#!'" v-on:click="$emit('detail', book_data.id)" class="book-title" style="-webkit-box-orient: vertical" v-html="book_data.title"></a>
		<div class="book-author">
			<p>by </p>
			<a href="#!" class="text-wrap" v-on:click="$emit('author_link', book_data.author)">{{ book_data.author }}</a>
		</div>
		<p class="price-discount" v-if="book_data.discount != 0">{{ book_data.price }}</p>
		<p class="price">{{ book_data.disc_price }}</p>
	</div>
</template>

<script>
	export default {
		props:[
		'book_data',
		],
		mounted() {
		},
		methods:{
			
		}
	}
</script>
