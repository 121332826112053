<template>
	<div>
		<div class="col-12" style="height: 2.25rem">
			<h3>Filter</h3>
		</div>
		<div class="col-12 p-3">

			<div v-if="route == 'search'">
				<div class="custom-control custom-checkbox mr-sm-2" v-for="data in filter_by_arr" v-if="data.count>0">
					<input type="checkbox" class="custom-control-input" v-on:click="$emit('filter_by', data.data)" :id="'check'+data.data">
					<label class="custom-control-label" :for="'check'+data.data">{{ data.label }} ({{ data.count }})</label>
				</div>

				<h4 class="mb-4">Kategori</h4>

				<div class="accordion mb-4" id="filterKategoriAccordion">

					<div class="card" v-for="(data, index) in arr_tema">
						<div class="card-header" :id="'filter'+data.id">
							<button class="btn btn-link" :class="{'collapsed':index !=0}" type="button" data-toggle="collapse" :data-target="'#filter'+data.id+'Collapse'" :aria-expanded="index==0?'true':'false'" :aria-controls="'filter'+data.id+'Collapse'">
								<p class="m-0 d-inline-block" :class="{'text-primary font-weight-bold' : data.selected_filter}" v-html="data.name"></p><span class="badge badge-pill badge-primary">{{ data.count }}</span>
								<span class="card-header-angle valign-wrapper"><i class="fa fa-angle-down"></i></span>
							</button>
						</div>

						<div :id="'filter'+data.id+'Collapse'" class="collapse" :class="{'show': index == 0}" :aria-labelledby="'filter'+data.id" data-parent="#filterKategoriAccordion">
							<div class="card-body">
								<button class="btn btn-link" type="button" v-for="(data1, index1) in data.sub_tema" v-on:click="$emit('filter_by_category', data1.id, index, index1)">
									<p class="m-0 d-inline-block" :class="{'text-primary font-weight-bold' : data1.selected_filter}" v-html="data1.name"></p><span class="badge badge-pill badge-primary">{{ data1.count }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div v-else>
				<div class="accordion mb-4" id="filterAccordion">

					<div class="card" v-for="(data, index) in filter_by_arr">
						<div class="card-header" :id="'filter'+data.data">
							<button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#filter'+data.data+'Collapse'" aria-expanded="false" :aria-controls="'filter'+data.data+'Collapse'">
								<p class="m-0 d-inline-block" :class="{'text-primary font-weight-bold' : data.selected_filter}" v-html="data.label"></p><span class="badge badge-pill badge-primary">{{ data.total }}</span>
								<span class="card-header-angle valign-wrapper"><i class="fa fa-angle-down"></i></span>
							</button>
						</div>

						<div :id="'filter'+data.data+'Collapse'" class="collapse" :aria-labelledby="'filter'+data.data" data-parent="#filterAccordion">
							<div class="card-body">
								<button class="btn btn-link" type="button" v-for="(data1,index1) in data.sub_filter" v-on:click="$emit('filter_by_category', data1.id, index, index1)">
									<p class="m-0 d-inline-block" :class="{'text-primary font-weight-bold' : data1.selected_filter}" v-html="data1.name"></p><span class="badge badge-pill badge-primary">{{ data1.count }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h4 class="mb-4">Harga</h4>

			<div class="PriceRange">
				<label for="minimumPrice">Minimum</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<div class="input-group-text">RP</div>
					</div>
					<input type="text" class="form-control" id="minimumPrice" placeholder="0" v-model="min_price" v-on:change="$emit('filter_min_price', min_price)">
				</div>
				<label for="maximumPrice">Maximum</label>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<div class="input-group-text">RP</div>
					</div>
					<input type="text" class="form-control" id="maximumPrice" placeholder="0" v-model="max_price" v-on:change="$emit('filter_max_price', max_price)">
				</div>
			</div>

			<div class="w-100 text-center">
				<button class="btn btn-primary" type="button" v-on:click="$emit('reset')">
					Reset
				</button>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props:[
		'arr_tema', 'filter_by_arr', 'route',
		],
		data(){
			return{
				min_price: '',
				max_price: '',
			}
		},
		mounted() {
		},
		methods:{
			// reset_button(){
			//   this.min_price = ''
			//   this.max_price = ''
			// }
		}
	}
</script>
